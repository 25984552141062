.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}
.rolitinerario {
    height: 100px;
    background-size: cover;
    background-position: 50%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
}
.rolitinerario.añade {    
    cursor: pointer;    
}
.rolitinerario div{
    line-height: 20px;
    height: 8vh;
    width: 79%;
    text-align: center;
    border-radius: 5px;
    padding: 15px 0px;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.rolitinerario div span{ 
    color: white;
    display: inline-block;
    vertical-align: middle;    
}
.rolitinerario div span.galeria{    
    font-weight: bolder;
    font-size: 1em;
    padding: 0;
}
.rolitinerario div div.reto-title{ 
    font-size: 0.7em;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    
}
.quitarol {
    position: absolute;
    top: 2px;
    right: 20px;
    cursor: pointer;
    color: black !important;
}
.col-3.retocont {
    overflow: hidden;
}
.retocont div div .galeria{
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    max-width: none;
    left: 0;
    padding: 10px;
    background-color: #28a745;
    
}
