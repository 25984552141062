.app {
    margin: 1rem 4rem;  
}

.app .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.app .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}

.app .ql-editor {
    min-height: 18em;
}

.themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }

.modalHeaderError{
    background: #dc3545;
}
.retos-imputs1 {
    border: 0;
    font-size: 10px;
    font-family: "Montserrat";
    color: #1f1d1d;
    background-color:#b8d2eb; 
    font-weight: 600;
    box-shadow: 0 0 10px 0 #999696;
    border-radius: 10px;
    max-width: 30%;
   margin-bottom: 10px;
   margin-right: 20px;
  }

  .retos-imputs2 {
    border: 0;
    font-size: 10px;
    font-family: "Montserrat";
    color: #1f1d1d;
    background-color:#b8d2eb; 
    font-weight: 600;
    box-shadow: 0 0 10px 0 #999696;
    border-radius: 10px;
    max-width: 30%;
   margin-bottom: 10px;
   maring-left: 20px;
  }
  .retos-number{
      font-size: 20px;
      margin-left: 55px;
  }

  .select-comp{
      width: 90%;
      margin-left: 25px;
  }

textarea{
    cursor: text;
}
.form-control::placeholder {
    color: #c5c3c5;
}

.extract {
    height: 400px;
}

.newcontent_docs{
    font-size: 40px;
    margin-top: 14px;
    margin-left: 4px;
    color: #cecbca;   
}

.newcontent_test{
    font-size: 40px;
    margin-top: 14px;
    color: #cecbca;   
}

.listado .comments{}

.listado .comments .comment {
    margin-bottom: 10px;

}
.listado .comments [name="comment"] {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 0px 13px 0 rgba(202, 201, 201, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: calc(100% - 10px);
    margin: 10px;
    border: 0;
    padding: 10px;
    font-size: 14px;
    font-weight: normal;
}
.listado .comments .avatar {
    position: relative;
    display: block;
    width: auto !important;
    height: 32px;
    margin-top: 10px;
    margin-left: 10px;
    line-height: 30px;
    border-bottom: 1px solid #ccc;
    font-weight: 600;
}
.listado .comments button {
    margin-top: 10px;
    line-height: 24px;
}

.main_content h4 {
    margin-top: 8px;
}