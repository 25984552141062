.avatarUser {
    width:120px;
    border-radius:70px;
    box-shadow: 2px 0px 12px #5D5D5F;
    border: 7px solid #FFFFFF;
    background-color: #FFFFFF;
    min-height: 120px;
    object-fit: cover;
}
.infoUser {
    margin-top: 15px;
}
.infoUser p {
    margin:1px;
}
.nameUser {
    font-weight: bold;
}
.nameUser a{
    color:#F1F1F1
}
.nameUser a:hover{
    color:#F1F1F1;
    
}
.nameUser a:visited{
    color:#F1F1F1
}
.cargoUser {
    color: #7a787c;
    font-weight: lighter;
}
.usuario{
    justify-content: center;
    overflow: hidden;
    z-index: 1001;
    text-align: center;
    padding: 0px 10px 15px 10px;
}