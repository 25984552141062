.newCourse-input{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
}

.newCourse-textarea{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 5px;
    max-height: 70%;
}

.newCourse-colImage{
    padding: 50px 10px 0 30px;
    max-width: 40%;
    margin-top: -87px;
}

.spanAlertCurso{
    color: red;
    font-family: "Montserrat";
    margin-top: 20px;
}

.newCourse-divContainer{
    margin-top:20px;
    margin-left: 1px;
}
.newCourse-colLista{
    max-width: 40%;
    width: 40%;
}

.newCourse-colLista{
    margin-left: 10px;
}
.newCourse-content{
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 0 30px 0 #999696;
    border-radius: 15px;
    width: 95%;
    height: 200px;
    margin-left: 20px;
}
.newCourse-Colinput{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
}

.newCourse-Select{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
    margin-left: 10px;
    max-width: 360px;
}

.newCourse-Select2{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
    margin-right: 20px;
    float: right;
    width: 100px;
}

.span-porcentaje{
    margin-top: 25px;
}

.newCourse-check{
    margin-top: 20px;
    margin-left: 20px;
}

.newCourse-contentSwap{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 31px; 
}

.newCourse-contentSwapLi{ 
    margin-top: 10px;
    font-family: 'Montserrat';
    color: #737373;
    font-weight: 600;
    padding: 18px 0 3px 1px;
}

.boton-guardar{
    max-width: 20%;
    width: 20%;
    margin-top: 20px;
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    margin-left: 585px;
}

.boton-add{
    max-width: 20%;
    width: 20%;
    margin-top: 20px;
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    margin-left: 560px;
}

.boton-add2{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
}

.boton-seleccionar{
    max-width: 30%;
    width: 30%;
    margin-top: 20px;
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    margin-left: 530px;
}

.newCourse-emptyContent{
    margin: 50px 0 15px 0;
    max-width: 40%;
}

.image-logo-curso{
    width: 30%;
    margin-bottom: 20px;
}

.newCourse-tableContent{
    width: 90%;
    margin-top: 20px;
}

@media (max-width: 1000px) {
    .boton-guardar {
        max-width: 40%;
        width: 40%;
        margin-left: 150px;
    }
  }

  @media (max-width: 1000px) {
    .boton-seleccionar {
        max-width: 40%;
        width: 40%;
        margin-left: 150px;
    }
  }

  @media (max-width: 1000px) {
    .boton-add {
        max-width: 30%;
        width: 30%;
        margin-left: 364px;
    }
  }