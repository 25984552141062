.modal-body .row .image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 150px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 16px;
}
.modal-body .row .image.video {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  margin-bottom: 15px;
}
.modal-body .row .image.video {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  margin-bottom: 15px;
}
.modal-body .row img {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  border-radius: 15px;
}
.modal-body .row video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 5px;
}
.modal-body .row .video {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}
.modal-body .row .image.audio {
  margin: 0px auto 16px auto;
}

.modal-body .row img {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.galeria {
  max-width: 70%;
}
.galeria .modal-content div div img {
  width: 200%;
  height: 100%;
}
/* .row .col .label:hover{
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    } */
.galeria .modal-content div div div:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.col-5.btn.btn-success {
  background: -webkit-gradient(
    linear,
    100% 0%,
    10% 21%,
    from(#54a525),
    to(#b1ed4d)
  );
  position: absolute;
  left: 20px;
}
.col-12.btn.btn-success {
  background: -webkit-gradient(
    linear,
    100% 0%,
    10% 21%,
    from(#54a525),
    to(#b1ed4d)
  );
  margin-top: 10px;
}

.modalHeaderSuccess {
  /* background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d)); */
  color: white;
  padding: 7px 16px;
}
.modal-title {
  font-size: 14px;
}
.modal-title strong {
  font-style: italic;
  font-weight: 600;
}

.galeria .modal-content div div div {
  height: 180px;
  background-size: cover;
  cursor: pointer;
  background-position: 50%;
  margin: 10px 0px;
}
.color-selected-overlay {
  width: 100%;
  height: 100% !important;
  opacity: 0.6;
  position: absolute !important;
  background: #1ba0ec;
  margin: 0px;
  left: 0;
  top: 0;
}
.color-selected-overlay:focus {
  outline: none !important;
}
.galeria .modal-content div div {
  padding-bottom: 40px;
  position: relative;
}
.row.modal-footer {
  min-height: 50px;
}
.galeria .modal-content div div div .color-selected-overlay {
  margin-top: 0px;
}

.col.text-center.btn.btn-light .label {
  margin-top: 0px;
  font-size: initial;
  font-weight: initial;
}
.reduceImage {
  width: 75% !important;
  height: 75% !important;
}
