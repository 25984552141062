.modal-registry .form-control{
    border: 1px solid #e7e8ea;
}

.modal-registry input.inputAvatar {
    box-shadow: none;
}

.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }
    
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}
.modal-registry .area_apps .area {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
}

.area {
    padding: 6px;
}

.row {
    display: flex;
    justify-content: center;
}
