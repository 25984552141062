
body {
  background: #f3f1f2;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

select, label {
  cursor: pointer;
}
.header{
  color:#F1F1F1;
  background-color: #161518;
  background-image: linear-gradient(to bottom right, #363739, #161518); 
  margin-left: 0px;
  margin-right: 0px;
}

.header_content {
  margin: 0 auto;
}
.textoblanco {
  color:#F1F1F1;
}

.content{
  background-color: #F6FBFF
}

.posnavbar {
  float: right;
  padding-top: 30px;
}
div.content {
  margin: 0 auto;
}

input{
  min-height:50px !important
}

.newCourse-Select {
  height: 50px !important;
}

html, .main_container{
  background: #f6fbff;
}

.btn-gradient-style, .boton-guardar, .boton-seleccionar{
  background-image: linear-gradient(#B0E94F, #459521) !important;
  border:0px;
  border-radius: 7px;
  height:50px;
}

.btn-gradient-styleReset{
  background-image: linear-gradient(#a82f27, #3d100d) !important;
  border:0px;
  border-radius: 7px;
  height:40px;
}

.btn-gradient-styleOk{
  background-image: linear-gradient(#B0E94F, #459521) !important;
  border:0px;
  border-radius: 7px;
  height:40px;
}


.boton-add2, .boton-add, .guardarborrador{
  background-image: linear-gradient(#B5B7B8, #7E7E7F) !important;
  border:0px;
  border-radius: 7px;
  height:50px;
}

.header_content, div.content{
  max-width: 1440px;
}  

.up_content {
  margin-top: -180px;
}
.rounded_cont{
  border-radius: 16px;
  background: #FFF;
  margin-bottom: 20px;
}
.rounded_cont.rounded-select{
  padding: 0;
}
.rounded_cont.rounded-select .select__control, select.form-control {
  border-radius: 16px !important;
  outline: none !important;
  padding-left: 12px;
}

.rounded_cont.img {
  max-height: 250px;
  overflow: hidden;
  padding: 0;
}
.rounded_cont_frame {
  overflow: hidden;
  border-radius: 10px;
}
.rounded_cont_frame input[type="file"] {
  font-size: 12px;
  margin: 8px 0px;
}
.rounded_cont img{
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: none;
  border-radius: 5px;
}
.rounded_cont .t2 {
  border: 0;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
  padding: 5px 14px;
  line-height: 50px;
  width: 100%;
  text-align: left;
}
.rounded_cont .ql-toolbar.ql-snow, .ql-container.ql-snow{
  border: 0 !important;
}

.rounded_cont.literal {
  background: #fdffd7;
  border-radius: 6px;
  padding: 20px 14px;
  font-style: italic;
  font-size: 12px;
}

.ojoOculto {
  float: right;
}
.card .author {
  background: #f7f5f7;
  border-radius: 6px;
  padding: 12px 14px;
}
.card .author .img {
  border-radius: 30px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 14px;
  margin-top: 14px;
}

.card .author img {
  width: 100%;
  border: 40px;
}

.card .author span {
  font-weight: 600;
  font-size: 1em;
  color: #a2a2a2;
}

.card .author .comment {
  font-size: 0.9em;
  font-weight: 600;
  padding-bottom: 8px;
}

.smooth-dnd-container {
  width: 100%;
}

.smooth-dnd-container span {
  display: block;
  width: 100%;
}

.comment_date {
  font-size: 0.75em;
  text-align: right;
  margin-top: 20px;
  position: relative;
  height: 22px;
}
.comment_date hr {
  background: #ccc;
  height: 2px;
  position: absolute;
  width: 100%;
  margin: 10px 0px;
}

.comment_date span {
  position: absolute;
  float: right;
  right: 0;
  top: 50%;
  margin-top: -8px;
  background: #fff;
  padding-left: 14px;
}

.published {
  font-size:1.1em;
  font-weight: bolder;
}
.hide {
  float: right;
  cursor: pointer;
  color: #fcdfa7;
  line-height: 37px;
}
.hide span {
  float: left;
  display: block;
  width: auto;
  line-height: 18px;
  font-size: 12px;
}
.hide svg {
  float: left;
  height: 18px;
}
.hide i {
  margin-top:5px;
}
.badge img {
  height:23px;
  width:23px;
}

.bg-orange {
  background-color: orange;
  padding: 0px 7px 0px 13px;
  line-height: 25px;
  border-radius: 20px;
  cursor: pointer;
  right: 0px;
}

.bg-orange:hover {
  background-color: darkorange;
}


.card.subtitle {
  padding-top: 20px !important;
  border-radius: 6px;
  padding: 14px 30px 6px !important;
}
.card.subtitle h3 {
  font-weight: 600;
  font-size: 20px;
  color: #c8c8c8;
}
th.actions {
  text-align: center;
}

.form-control {
  border: 0;
  font-size: 13px;
  font-family: "Montserrat";
  color: #737373;
  font-weight: 600;
  border:1px solid gainsboro;
  /* box-shadow: 0 0 30px 0 #999696; */
  border-radius: 15px;
 
}
.form-control.shadow {
  border: 1px solid #dedede;
}

.ql-container{
    max-height: 300px !important;
    height: 300px !important;
}
.ql-editor {
    font-weight: 600;
    font-family: "Montserrat";
    letter-spacing: 0.7px;
}
.beauty-checkbox input{
  display: none;
}

.content_type_container{
  margin-bottom: 20px;
}

.content_type_container .col {
  position: relative;
  overflow: hidden;
  background: #FFF;
  height: 90px;
  width: calc(100% - 10px);
  margin: 0px 15px;
  border-radius: 16px;
  cursor: pointer;
}
.content_type {
  border-radius: 16px;
  color: #f7d308;
  font-size: 17px;
  font-weight: bolder;
  position: absolute;
  bottom: 0 !important;
  text-align: center;
  padding-bottom: 8px;
  width: calc(100% - 30px);
}


.content_type_container .active, .content_type_container .active div{
  -webkit-filter: none !important;
          filter: none !important;
}

.content_type_container div{
  -webkit-filter: grayscale(100%) !important;
          filter: grayscale(100%) !important;
}

.content_type_container .texto{
  background: #FFF url(/img/ico/sprite_file_type.png) no-repeat 50% 0px;
}
.content_type_container .audio {
  background: #FFF url(/img/ico/sprite_file_type.png) no-repeat 50% -92px;
}
.content_type_container .video {
  background: #FFF url(/img/ico/sprite_file_type.png) no-repeat 50% -192px;
}



#trigger {
  display: none;
}
.checker {
  background-image: url(/img/ico/checkboxes.png);
  background-position: left center;
  background-size: auto 100%;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
}
#trigger:checked + .checker {
  background-position: right center;
}


button.btn.btn-secondary, button.btn.btn-primary {
  font-size: 13px;
  width: 100%;
  overflow: hidden;
  font-weight: 600;
  margin-right: 0;
  margin-right: 0;
  padding: 8px;
}
button.btn.btn-success.borrador {
  background: #ccc;
  border-color: #ccc;
  color: #000;
  
}
.btn_save {
  margin-top: 20px;
}

.no-gutters {
  max-width: 50%;
  flex: 50% 1;
}
.cBXKgf, .dJewwL{
  background: none !important;
}

.shadow{
box-shadow: 0px 0px 20px #0000001a;

}

.rounded_cont.contents{

    margin-bottom: 10px;
    padding: 15px;
    font-size: 12px;
}

.rounded_cont.contents .actions button, .rounded_cont.contents .actions a {
  float: right;
  margin: 0;
  margin-left: 6px;
  padding: 5px 10px;
  font-size: 13px;
}
.rounded_cont.contents .actions a {
  padding: 0;
}
.contents .entry {
  font-weight: 600;
  line-height: 20px;
}

.contents .excerpt {
  font-style: italic;
}

.contents .published_date{
  font-weight: 600;
  font-style: italic;
}

tr:nth-child(odd) {
  background-color:#f2f2f2;
}
tr:nth-child(even) {
  background-color:#fbfbfb;
}
thead tr th {
  background-color:#fbfbfb;
  color: #CCCCCC;
}

td.showComment {
  cursor: pointer;
  text-align: center;
}
th.showComment {
  text-align: center;
}
td.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}
th.delete {
  text-align: center;
}
td.showComment img {
  height:23px;
  width:23px;
}
td.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}
.tablaComentarios tbody tr td.entry{
  font-weight: lighter;
}
.tablaComentarios tbody tr td.comments{
  font-weight: bolder;
}
.tablaComentarios {
  vertical-align: middle;
}

.rounded_cont.contents.shadow{
  padding:0px;
  margin:0px 0px 20px 0px;
}
.table-light.table{
  margin-bottom:0px;
}

.edit {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}
.edit a:hover {
  color: black;
}
.visits {
  text-align: center;
}
table .comments {
  text-align: center;
}
.published_date {
  text-align: center;
}
.published_date span{
  /*font-weight: lighter;*/
  font-style: normal;
}
.excerpt span{
  /*font-weight: lighter;*/
  font-style: normal;
}

.rotate {
  transform: rotate (180deg);
}

tr:nth-child(odd) {
  background-color:#f2f2f2;
}
tr:nth-child(even) {
  background-color:#fbfbfb;
}
thead tr th {
  background-color:#fbfbfb;
  color: #CCCCCC;
}
.user{
  font-weight: bolder;
  font-size: 1rem;
}
.showComment {
  cursor: pointer;
  text-align: center;
}
.showComment {
  text-align: center;
}
.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}

.delete {
  text-align: center;
}

td.showComment img {
  height:23px;
  width:23px;
}

td.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}

.tablaUsuarios tbody tr td.entry{
  font-weight: lighter;
}

.tablaUsuarios tbody tr td.comments{
  font-weight: bolder;
}

.tablaUsuarios {
  vertical-align: middle;
}

.table thead th {
  font-size: 0.9em;
}

.main_content h3 {
  color:rgb(121, 121, 121);
  font-weight:bold;
}

.main_content h4 {
  color: #7d7d7d;
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
  margin: 0 0 10px 0;
}

.table-light.table{
  margin-bottom:0px;
}

td.avatar img{
  width:60px;
  border-radius:70px;
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
}

.row div a.btn.newUser{
  background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
  color: #FFF;
  opacity: 0.8;
  padding: 5px 15px;
  margin-left: 12px;
  border-radius: 5px;
  font-size: 11px;
}

.row div a.btn.altas{
  background: #d8d8d8;
  color: #8b8b8b;
  opacity: 0.8;
  padding: 5px 15px;
  margin-left: 12px;
  border-radius: 5px;
  font-size: 11px;
}



.modal-registry label {
  font-size: 0.7rem;
  font-weight: bolder;
}

.modal-registry input {
  font-size: 0.7rem;
  font-weight: bolder;    
  border-radius: 5px;
  height:38px;
}

.modal-registry select {
  font-size: 0.7rem;
  font-weight: bolder;    
  border-radius: 5px;
}

.modal-registry [id^="ad_"] {
  height: 18px;
  margin-right: 8px;
  display: block;
  float: left;
}

.modal-registry [for^="ad_"] {
  margin: 0 5px 0px 0;
  display: block;
  float: left;
}

.modal-registry h3 {
  font-size: 12px;
}

.modal-registry .area_admin, .modal-registry .area_apps{
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;
}
.modal-registry .area_admin .area{
  width: calc(100% / 3);
  float: left;
}

.undefinedmarcomodal {
  width:75%;
  max-width: 800px;

}

.modal-footer .btn.newUser{
  background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
  color: #FFF;
  opacity: 0.8;
  padding: 5px 15px;
  margin-left: 12px;
  border-radius: 5px;
  font-size: 11px;
}   

.list_categorias .thumbnail {
  width: 12%;
}
.list_categorias .title, .list_categorias .total, .list_categorias .parent_category{
  line-height: 20px;
  padding-top: 18px;
}
.list_categorias .title, .list_categorias .total, .list_categorias .parent_category{
  font-size: 12px;
}
.list_categorias .title {
    width: 32%;
    font-size: 14px;
    font-weight: 600;
}
.list_categorias .total {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  width: 16%;

}

.list_categorias .parent_category {
  text-align: center;
  width: 20%;
  
  font-size: 14px;
  font-weight: 600;
}

.list_categorias td.edit, .list_categorias td.delete {
  width: 10%;
line-height: 50px;
}

.add-category {
  margin-top: 20px;
}

.form-category label {
  float: left;
  display: block;
  padding: 10px 33px 0px 4px;
  font-weight: 600;
}

.form-category .rounded_cont {
  padding: 10px;
}

.form-category .form-control {
  font-size: 14px;
}

.form-category .rounded_cont.img {
  padding: 10px 20px 20px 20px;
}

.form-category h2 {
  font-size: 21px;
  font-weight: 600;
  margin: 10px 0px 15px 20px;
}

.add-category span {
  display: block;
  background: #28a745;
  color: #FFF;
  overflow: hidden;
  width: auto;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  margin: 0 auto;
}

.cat-modal{
  display: block; 
  width: 1200px; 
  padding-left: 0px;
}

.listado .svg-inline--fa {height: 1.5em; width: auto;}

.listado .rt-th {
  line-height: 60px !important;
  font-size: 14px;
  font-weight: 600;
}

.listado .rt-th div{
  color: #9aa5a9;
}

.listado .rt-th.title div, .listado .rt-th.category div, .listado .rt-th.user div, .listado .rt-th.file div, .listado .rt-th.category div, .listado .rt-th.email div, .listado .rt-th.author div, .listado .rt-th.comment div {
  text-align: left;
  padding: 0 5px;
}

.listado .rt-tbody .rt-td {
  border-right: 0px solid rgba(0,0,0,0.02);
  display: flex;
  justify-content: center;
  align-items: center;
}

.listado .rt-td div {
  line-height: 60px;
  font-size: 14px;
  font-weight: 600;
  color: #9aa5a9;
  text-align: center;
  padding: 0px 5px;
  width: 100%;
  overflow: hidden;
}

.listado .rt-td a{ color: inherit; }

.listado .rt-td .user, .listado .rt-td .file, .listado .rt-td .category, .listado .rt-td .title, .listado .rt-td .author{
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  color: #363a46;
}
.listado .rt-td .image, .listado .rt-td .avatar {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listado .rt-td .author {
  text-align: center;
  font-weight: 800;
  text-align: left;
}
.listado .rt-td .author i {
  font-weight: normal;
  color: #d4d4d4;
}
.listado .rt-td .comment {
  text-align: left;
  line-height: 20px;
  padding: 0px;
  height: 100%;
  white-space: normal;
}
.listado .rt-td .email{
  text-align: left;
}
.listado .rt-td .image img, .listado .rt-td .avatar img {
  max-width: none;
  width: auto;
  height: 100%;
}

.listado .rt-td .estado {
  color: #d5be18;
}

.listado .rt-td .estado .draft {
    font-style: italic;
    color: #ff9292;
}

.galeria .modal-footer {
  width: 100%;
  margin: 0;
}

.listado .rt-td svg{
  height: 16px;
}

.listado .rt-td .delete, .listado .rt-td .edit{
    color: #000;
    font-size: 15px;
    line-height: 15px;
}



.listado .rt-td .visible {
  max-width: 40px;
  cursor: pointer;
}

textarea {
  border: 0;
  background: none;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  outline: none;
  resize: none;
  cursor: none;
}

textarea.notification {
  height: 150px !important;
  color: #808080;
}

.new-content-image label.choose{
/* margin-top: 40px; */
margin-top: 10px;
cursor: pointer;
}
.new-content-image label.choose:hover{
text-decoration: underline;
box-shadow: none;

}
.new-content-image input {
  font-size: 12px;
  padding: 0;
}
.new-content-image .carga-imagen {
  line-height: 20px;
  height: auto;
}

.new-content-image .carga-imagen div {
  height: auto;
  padding: 1px 15px;
  margin: 4px 0px;
  line-height: 30px;
}
.new-content-image .carga-imagen div:nth-child(2) {
  margin-right: 0;
  margin-left: 7px;
}

.new-content-image .carga-imagen svg {
  
  max-height: 13px;
  width: auto;
}

.new-content-image .carga-imagen svg + label {
  margin-left: 8px;
  cursor: pointer;
}

.new-content-image .carga-imagen label {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #7d7d7d;
    margin: 0;
}

.uploadmedia_buttons_modalfooter.modal-footer {
  padding: 0 0;
  margin: 0;
  margin-bottom: 15px;
}

.uploadmedia_buttons_modalfooter.modal-footer button {
  margin: 0;
}

.new-content-image input[type="file"] {
  background: #ccc;
  display: block !important;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
}

.new-content-btn {
  display: block;
}
.add-media{
  margin-top: 20px;
}

.react-datetime-picker, .react-datetime-picker__button {
  width: 100%;
  background: #FFF;
  padding: 5px;
  border: 0 !important;
}
.react-datetime-picker{
  padding: 10px;
}
.react-datetime-picker__button__input__divider {
  padding: 1px 4px !important;
}

.react-datetime-picker__button{
  padding: 0;
  display: flex;
  border: thin solid gray;
  width: 97.5%;
  margin: 0 auto;
}

.rounded_cont.calendar {
  overflow: inherit;
  border-radius: 16px;
  margin-top: 5px;
}

.rounded_cont.borrador {
  padding: 15px;
  line-height: 22px;
  margin: 0 0 20px 0;
}

.rounded_cont.borrador label {
  margin: 0;
  cursor: pointer;
}

.rounded_cont.borrador label em{
  font-size: 12px;
  padding-left: 10px;
  font-style: normal;
  font-weight: 600;
}

.react-datetime-picker__calendar {
  width: 100% !important;
}

.react-datetime-picker__calendar .react-calendar {
  width: 90%;
  margin: 0 auto 15px auto;
  border-radius: 4px;
  border: 1px solid black;
  background: #f3ffe7;
  padding: 3px;
}

.react-datetime-picker__calendar, .react-datetime-picker__clock {
  bottom: 100%;
  margin-bottom: 14px;
  top: inherit !important;
  padding: 15px 0px 0 0;
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.react-datetime-picker__clear-button{
  display: none;
}

.react-calendar__tile--active {
  background: black !important;
  border-radius: 3px;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: black!important;
  color: #FFF;
  border-radius: 3px;
}

button.react-calendar__tile:enabled:hover, button.react-calendar__tile:enabled:focus {
  background-color: #ddf7c4;
}

.modal-body .modalChoise{
  width: 5%;
}

.modal-body .react-datetime-picker {
  width: 95%;
  padding: 0;
  margin: 10px 0 10px -7px;
}

.react-datetime-picker__button__input__input {
  padding: 0px 6px !important;
  margin: 0 0px;
  cursor: pointer;
  border-radius: 2px;
  outline: none;
  font-weight: 600 !important;
  color: #8c8c8c;
}

.react-datetime-picker__button__input__input:hover {
  background: black;
  color: #FFF;
}

.react-datetime-picker__clock {
  
  border: 0 !important;
  margin: 0 auto;
  width: 100% !important;
  background: none !important;
}
button.react-calendar__tile:enabled:hover, button.react-calendar__tile:enabled:focus {
  background-color: black;
}


.react-datetime-picker__button__icon {
  margin: 0;
}

.react-clock {
  margin: 0 auto;
}

.react-clock__face {
  background: #c7eda2;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 10px;
  float: left;
  margin-left: 14px;
  width: 100%;
  text-decoration: none;
}

.pagination li {
  width: 30px;
  height: 30px;
  background: #007bff;
  margin: 1px;
}

.pagination a {
  width: 100%;
  height: 100%;
  line-height: 30px;
  color: #FFF !important;
  display: block;
}

.pagination a:hover {
  background: #046ad6;
  text-decoration: none;
}

.list-search:hover{
  background-color: rgba(194, 194, 194, 0.925);
}

.list-search {
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 14px;
  padding: 10px 20px 10px 30px;
  background: url(/img/search.png) no-repeat 13px;
  background-size: 20px;
  font-weight: 600;
  font-style: italic;
  background-color: gainsboro;
}

.form-control:focus {
  box-shadow: none;
  color: #808080;
}

td.valign{
  vertical-align: middle;
}
.coverAll {
  z-index: 1000;
  background-color: #333333de;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#subemedio {
  background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(black));
}

.sin-asignar {
  margin-top: 10px;
  font-size: 12px;
  font-style: italic;
  color: #807e80;
}

.sin-asignar span {
  font-weight: 600;
}

.copyURL {
  background: url(/img/copy.png) no-repeat 50% !important;
  display: block;
  width: 30px;
  height: 30px;
  background-size: 50% !important;
  background-color: #007bff !important;
  border-radius: 6px;
  position: absolute;
  left: -5px;
  z-index: 10;
  cursor: pointer;
  outline: none;
}
.copyURL.copied {
  background-color: #62886a !important;
}

.copyURL-txt {
  background: #fffffff0;
  overflow: hidden;
  width: 84%;
  display: block;
  position: absolute;
  right: 0;
  font-size: 12px;
  overflow: hidden;
  line-height: 30px;
  height: 30px;
  cursor: text;
  border-radius: 5px;
  z-index: 1;
  border: 1px solid #e0e0e0;
  margin-right: 15px;
}

.main_content .row .btns{
  padding-top: 10px;
  padding-left: 5px;
}

.main_content .row .newUser.btn {
  background: #28a745;
  color: #FFF;
  overflow: hidden;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  width: auto;
  padding: 5px 15px;
  font-size: 12px;
  margin-top: 10px;
}

.mediacont .video{
  position: relative !important;
  padding: 0 0 0 0 !important;
  width: 100%;
  height: auto !important;
}

.mediacont .video video {
  width: 100%;
  top: 0;
  border-radius: 5px;
  overflow: hidden;
  max-height: 157px;
  background: #525252;
}

.mediacont .audio audio {
  margin: 48px auto 0 auto;
  width: 96%;
}
.mediacont .video, .mediacont .audio {
  height: 180px;
  background-size: cover;
  cursor: pointer;
  background-position: 50%;
  margin: 10px 0px;
  padding: 9px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #135823;
  color: #FFF;
}

.mediacont .media-title {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  display: block;
  padding: 0;
  height: 50px;
}

.form-category input[type="file"] {
  font-size: 10px;
}

span.publish_date{
  font-size: 12px;
  color: #7d7d7d;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  display: block;
  padding-bottom: 1px;
  margin-bottom: 6px;
}
span.publish_date + time {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
}


.pretty.p-switch.p-fill input:checked~.state label:after {
  left: calc(1em);
}

.pretty.p-switch .state label:after, .pretty.p-switch .state label:before {
  left: 2px;
  margin-top: -1px;
}

.pretty.p-switch .state:before {
  border: 2px solid #bdc3c7;
  height: 1em;
}


.scheduled {
  font-weight: normal;
}
.rounded_cont .destacada img {
  height: 26px;
  width: auto;
  cursor: pointer;
}

.literal {
  color: #FFF;
  font-size: 13px;
  border-radius: 16px;
  padding: 12px 16px;
  background: #5f5f5f;
  margin-bottom: 20px;
}

.image.modal-image {
  width: 100%;
  max-height: none !important;
}

.text-muted {
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (max-width: 1240px) {
  .header_content, .content {
    max-width: 1240px;
  }
  
}

@media screen and (max-width: 992px) {
  .header_content, .content {
    padding: 10px;
  }
  .up_content {
    margin-top: 15px;
  }
  .logo{
    max-width: 300px;
    margin: 0 auto;
  }
}
.logo {
    overflow: hidden;
    padding: 30px;
}
.logo img {
    width: 100%;
}
.avatarUser {
    width:120px;
    border-radius:70px;
    box-shadow: 2px 0px 12px #5D5D5F;
    border: 7px solid #FFFFFF;
    background-color: #FFFFFF;
    min-height: 120px;
    object-fit: cover;
}
.infoUser {
    margin-top: 15px;
}
.infoUser p {
    margin:1px;
}
.nameUser {
    font-weight: bold;
}
.nameUser a{
    color:#F1F1F1
}
.nameUser a:hover{
    color:#F1F1F1;
    
}
.nameUser a:visited{
    color:#F1F1F1
}
.cargoUser {
    color: #7a787c;
    font-weight: lighter;
}
.usuario{
    justify-content: center;
    overflow: hidden;
    z-index: 1001;
    text-align: center;
    padding: 0px 10px 15px 10px;
}
.ml-auto li{
    margin-left: 20px;
}

.ml-auto li a:hover{
    color: #FFF;
}

.ml-auto li a svg {
    margin-left: 6px;
}

.nav_side .nav_ul {
    border-radius: 12px;
    width: 100%;
}

.nav_side .nav_li {
    margin-top: 12px;
    border-radius: 16px;
    padding-left: 64px;
    background: #FFF url(/img/ico/sprite_nav_vertical.png) no-repeat 9px 0;
    line-height: 34px;
    display: block;
    transition: All 0.4s ease;
    -webkit-transition: All 0.4s ease;
    -moz-transition: All 0.4s ease;
    -o-transition: All 0.4s ease;
}

.nav_side .nav_li:hover{
    background: #35a2d3 url(/img/ico/sprite_nav_vertical_hover.png) no-repeat 9px 0;
    cursor: pointer;
    
}

.nav_side .nav_li a {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 49px;
    display: block;
    width: 100%;
    color: #807e80;
}

.nav_side .nav_li a:hover{
    text-decoration: none;
}

.nav_side .nav_li:hover > a{
    color: #FFF;
}

li.nav_li.content {
    background-position-y: -245px;
}

li.nav_li.new {
    background-position-y: 0px;
}
li.nav_li.contents, li.nav_li.gestion{
    background-position-y: -45px;
}
li.nav_li.comments{
    background-position-y: -95px;
}
li.nav_li.media {
    background-position-y: -146px;
}
li.nav_li.categorias {
    background-position-y: -196px;
}
li.nav_li.notification {
    background-position-y: -630px;
    background-position-x: 8px;
}
li.nav_li.home {
    background-position-y: -245px;
    margin: 12px 0 0 0;
}
li.nav_li.users {
    background-position-y: -296px;
}

li.nav_li.retos {
    background-position-y: -403px;
    background-position-x: 8px;
}

li.nav_li.logros {
    background-position-y: -520px;
    background-position-x: 8px;
}

li.nav_li.itinerarios {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.listadocursos {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.questions {
    background-position-y: -681px;
    background-position-x: 8px;
}

li.nav_li.metricas, li.nav_li.training {
    background-position-y: -247px;
    background-position-x: 8px;
}

li.nav_li.inicio, li.nav_li.focus {
    background-position-y: -247px;
    background-position-x: 8px;
}

/* hover */

li.nav_li.content:hover {
    background-position-y: -245px;
}

li.nav_li.new:hover {
    background-position-y: 0px;
}
li.nav_li.contents:hover, li.nav_li.gestion:hover {
    background-position-y: -45px;
}
li.nav_li.comments:hover {
    background-position-y: -95px;
}
li.nav_li.media:hover {
    background-position-y: -146px;
}
li.nav_li.categorias:hover {
    background-position-y: -196px;
}
li.nav_li.notification:hover {
    background-position-y: -630px;
    background-position-x: 8px;
}
li.nav_li.home:hover {
    background-position-y: -245px;
    margin: 12px 0 0 0;
}
li.nav_li.users:hover {
    background-position-y: -296px;
}

li.nav_li.retos:hover {
    background-position-y: -403px;
    background-position-x: 8px;
}

li.nav_li.logros:hover {
    background-position-y: -520px;
    background-position-x: 8px;
}

li.nav_li.itinerarios:hover {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.listadocursos:hover {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.questions:hover {
    background-position-y: -681px;
    background-position-x: 8px;
}
li.nav_li.exam, li.nav_li.exam:hover {
    background-position-y: -733px;
    background-position-x: 8px;
}
li.nav_li.convocatoria, li.nav_li.convocatoria:hover {
    background-position-y: -783px;
    background-position-x: 8px;
}

li.nav_li.metricas:hover, li.nav_li.training:hover {
    background-position-y: -247px;
    background-position-x: 8px;
}

li.nav_li.inicio, li.nav_li.focus:hover {
    background-position-y: -247px;
    background-position-x: 8px;
}

.load {
    background: white;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 100000000;
    opacity: 0.9;
    border-radius: 6px;
}

.load div {
    z-index:30000000;
    position: absolute;    
    margin: auto;
    top:0;
    bottom:0;
    left:0;
    right:0;    
    
}

#root > div > div.content > div > div.col-lg-9.up_content > div > div.rounded_cont.contents.shadow.col-sm-6 > div > div {
    margin:2rem;
}
#root > div > div.content > div > div.col-lg-9.up_content > div > div.rounded_cont.contents.shadow.col-sm-6 > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div,#root > div > div.content > div > div.col-lg-9.up_content > div > div.rounded_cont.contents.shadow.col-sm-6 > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div {
    padding-left:10px;
    padding-right:10px;
}
#statUsers .moduloStat .progress{
    height: 1.7rem;
    border-radius: 0.6rem;
}
#statUsers .moduloStat .progress .bg-success{
    background-image: linear-gradient(to right, #67B030 , #B4EC51);
}
#statUsers .moduloStat .progress .bg-danger{
    background-image: linear-gradient(to right, #A70B21 , #D33345);
}

#statUsers .row.moduloStat .row, #statUsers .row.masInfo .row{
    display: flex;
    align-items: center;
}
#statUsers .row.moduloStat .nameStat, #statUsers .row.masInfo .nameStat {
    font-size: 1rem;
    font-weight:bolder;
}
#statUsers .row.moduloStat .numStat, #statUsers .row.masInfo .numStat{
    font-size: 2rem;
    font-weight:bolder;
    color: #5C5C5C;
}
#statUsers .row.masInfo .dcha{
 float:right;
 padding-right:10px;
}
#analyticUp {
    margin: 5px;
}
#analyticUp .rounded_cont.contents.shadow.col{
    padding:20px;
    margin: 5px;
}
/* .fichaCurso {
    margin-bottom: 15px;
    box-shadow:1px;
} */
.rotateFlecha {
    transform: rotate(90deg);
}
.infoAprobados {
    margin-bottom: 10px;
    font-size: 0.9rem;
    margin-top:-10px;
    padding-left:25px;
}

.filterRol{
    max-width: 340px;
    /* max-height: 20px; */
}
input.form-control.list-search{
    height: 65px !important;
}
.mapblock {
  padding: 10px; }



.rounded_contAn{
    border-radius: 16px;
    background: #FFF;
    margin-bottom: 20px;
  }

.rounded_contAn.contents{
    margin-bottom: 10px;
    padding: 15px;
    font-size: 12px;
}  

.statCol{
    height: 200px;
    max-width: 250px;
    margin-left: 20px;
}

.statText{
    font-size: 1rem;
    font-weight:bolder;
}



.pickerCol{
    max-width: 300px;
    margin-bottom: 20px;
}

.selectCol{
    max-width: 300px;
    margin-top: 25px;
}

.pick{
    border-radius: 16px;
}



.pickerCol{
    max-width: 300px;
    margin-bottom: 20px;
}

.selectCol{
    max-width: 300px;
    margin-top: 25px;
}

.pick{
    border-radius: 16px;
}


.app {
    margin: 1rem 4rem;  
}

.app .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.app .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}

.app .ql-editor {
    min-height: 18em;
}

.themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }

.modalHeaderError{
    background: #dc3545;
}
.retos-imputs1 {
    border: 0;
    font-size: 10px;
    font-family: "Montserrat";
    color: #1f1d1d;
    background-color:#b8d2eb; 
    font-weight: 600;
    box-shadow: 0 0 10px 0 #999696;
    border-radius: 10px;
    max-width: 30%;
   margin-bottom: 10px;
   margin-right: 20px;
  }

  .retos-imputs2 {
    border: 0;
    font-size: 10px;
    font-family: "Montserrat";
    color: #1f1d1d;
    background-color:#b8d2eb; 
    font-weight: 600;
    box-shadow: 0 0 10px 0 #999696;
    border-radius: 10px;
    max-width: 30%;
   margin-bottom: 10px;
   maring-left: 20px;
  }
  .retos-number{
      font-size: 20px;
      margin-left: 55px;
  }

  .select-comp{
      width: 90%;
      margin-left: 25px;
  }

textarea{
    cursor: text;
}
.form-control::-webkit-input-placeholder {
    color: #c5c3c5;
}
.form-control::placeholder {
    color: #c5c3c5;
}

.extract {
    height: 400px;
}

.newcontent_docs{
    font-size: 40px;
    margin-top: 14px;
    margin-left: 4px;
    color: #cecbca;   
}

.newcontent_test{
    font-size: 40px;
    margin-top: 14px;
    color: #cecbca;   
}

.listado .comments{}

.listado .comments .comment {
    margin-bottom: 10px;

}
.listado .comments [name="comment"] {
    box-shadow: 0 0px 13px 0 rgba(202, 201, 201, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: calc(100% - 10px);
    margin: 10px;
    border: 0;
    padding: 10px;
    font-size: 14px;
    font-weight: normal;
}
.listado .comments .avatar {
    position: relative;
    display: block;
    width: auto !important;
    height: 32px;
    margin-top: 10px;
    margin-left: 10px;
    line-height: 30px;
    border-bottom: 1px solid #ccc;
    font-weight: 600;
}
.listado .comments button {
    margin-top: 10px;
    line-height: 24px;
}

.main_content h4 {
    margin-top: 8px;
}

.modal-body .row .image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 150px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 16px;
}
.modal-body .row .image.video{
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    margin-bottom: 15px;
}
.modal-body .row .image.video{
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    margin-bottom: 15px;
}
.modal-body .row img {
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    border-radius: 15px;
    
}
.modal-body .row video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 5px;
  }
.modal-body .row .video{
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
}
.modal-body .row .image.audio {
    margin: 0px auto 16px auto;
}

.modal-body .row img {
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    
}






.galeria {
     max-width: 70%
    }
    .galeria .modal-content div div img {
        width:200%;
        height:100%;
    }
    /* .row .col .label:hover{
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    } */
    .galeria .modal-content div div div:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
    .col-5.btn.btn-success {
        background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
        position: absolute;
        left:20px;
    }
    .col-12.btn.btn-success {
        background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
        margin-top: 10px;
    }
   
    .modalHeaderSuccess {
        /* background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d)); */
        color: white;
        padding: 7px 16px;
    }
    .modal-title {
        font-size: 14px;
    }
    .modal-title strong {
        font-style: italic;
        font-weight: 600;
    }

    .galeria .modal-content div div div {
        height: 180px;
        background-size: cover;
        cursor: pointer;
        background-position: 50%;
        margin: 10px 0px;
    }
    .color-selected-overlay {
        width: 100%;
        height: 100% !important;
        opacity: 0.6;
        position: absolute !important;
        background: #1ba0ec;
        margin: 0px;
        left: 0;
        top: 0;
    }
    .color-selected-overlay:focus{
        outline: none !important;
    }
    .galeria .modal-content div div {
        padding-bottom: 40px;
        position: relative;
    }
    .row.modal-footer {
        min-height:50px;
    }
    .galeria .modal-content div div div .color-selected-overlay {
        margin-top:0px;
    }

    .col.text-center.btn.btn-light .label {
        margin-top: 0px;
        font-size: initial;
        font-weight:initial;
    }
    
.modal-body .row .image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 150px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 16px;
}
.modal-body .row .image.video {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  margin-bottom: 15px;
}
.modal-body .row .image.video {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  margin-bottom: 15px;
}
.modal-body .row img {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  border-radius: 15px;
}
.modal-body .row video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 5px;
}
.modal-body .row .video {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}
.modal-body .row .image.audio {
  margin: 0px auto 16px auto;
}

.modal-body .row img {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.galeria {
  max-width: 70%;
}
.galeria .modal-content div div img {
  width: 200%;
  height: 100%;
}
/* .row .col .label:hover{
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    } */
.galeria .modal-content div div div:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.col-5.btn.btn-success {
  background: -webkit-gradient(
    linear,
    100% 0%,
    10% 21%,
    from(#54a525),
    to(#b1ed4d)
  );
  position: absolute;
  left: 20px;
}
.col-12.btn.btn-success {
  background: -webkit-gradient(
    linear,
    100% 0%,
    10% 21%,
    from(#54a525),
    to(#b1ed4d)
  );
  margin-top: 10px;
}

.modalHeaderSuccess {
  /* background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d)); */
  color: white;
  padding: 7px 16px;
}
.modal-title {
  font-size: 14px;
}
.modal-title strong {
  font-style: italic;
  font-weight: 600;
}

.galeria .modal-content div div div {
  height: 180px;
  background-size: cover;
  cursor: pointer;
  background-position: 50%;
  margin: 10px 0px;
}
.color-selected-overlay {
  width: 100%;
  height: 100% !important;
  opacity: 0.6;
  position: absolute !important;
  background: #1ba0ec;
  margin: 0px;
  left: 0;
  top: 0;
}
.color-selected-overlay:focus {
  outline: none !important;
}
.galeria .modal-content div div {
  padding-bottom: 40px;
  position: relative;
}
.row.modal-footer {
  min-height: 50px;
}
.galeria .modal-content div div div .color-selected-overlay {
  margin-top: 0px;
}

.col.text-center.btn.btn-light .label {
  margin-top: 0px;
  font-size: initial;
  font-weight: initial;
}
.reduceImage {
  width: 75% !important;
  height: 75% !important;
}


.newCourse-input{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
}

.newCourse-textarea{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 5px;
    max-height: 70%;
}

.newCourse-colImage{
    padding: 50px 10px 0 30px;
    max-width: 40%;
    margin-top: -87px;
}

.spanAlertCurso{
    color: red;
    font-family: "Montserrat";
    margin-top: 20px;
}

.newCourse-divContainer{
    margin-top:20px;
    margin-left: 1px;
}
.newCourse-colLista{
    max-width: 40%;
    width: 40%;
}

.newCourse-colLista{
    margin-left: 10px;
}
.newCourse-content{
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 0 30px 0 #999696;
    border-radius: 15px;
    width: 95%;
    height: 200px;
    margin-left: 20px;
}
.newCourse-Colinput{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
}

.newCourse-Select{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
    margin-left: 10px;
    max-width: 360px;
}

.newCourse-Select2{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
    margin-right: 20px;
    float: right;
    width: 100px;
}

.span-porcentaje{
    margin-top: 25px;
}

.newCourse-check{
    margin-top: 20px;
    margin-left: 20px;
}

.newCourse-contentSwap{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 31px; 
}

.newCourse-contentSwapLi{ 
    margin-top: 10px;
    font-family: 'Montserrat';
    color: #737373;
    font-weight: 600;
    padding: 18px 0 3px 1px;
}

.boton-guardar{
    max-width: 20%;
    width: 20%;
    margin-top: 20px;
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    margin-left: 585px;
}

.boton-add{
    max-width: 20%;
    width: 20%;
    margin-top: 20px;
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    margin-left: 560px;
}

.boton-add2{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
}

.boton-seleccionar{
    max-width: 30%;
    width: 30%;
    margin-top: 20px;
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    margin-left: 530px;
}

.newCourse-emptyContent{
    margin: 50px 0 15px 0;
    max-width: 40%;
}

.image-logo-curso{
    width: 30%;
    margin-bottom: 20px;
}

.newCourse-tableContent{
    width: 90%;
    margin-top: 20px;
}

@media (max-width: 1000px) {
    .boton-guardar {
        max-width: 40%;
        width: 40%;
        margin-left: 150px;
    }
  }

  @media (max-width: 1000px) {
    .boton-seleccionar {
        max-width: 40%;
        width: 40%;
        margin-left: 150px;
    }
  }

  @media (max-width: 1000px) {
    .boton-add {
        max-width: 30%;
        width: 30%;
        margin-left: 364px;
    }
  }
h3{
 font-size: 50px;
}
.listacursos-col{
    margin-top: 20px
}

.btn-gradient-style{
background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
}

.btn-gradient-style2{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    max-width: 150px;
    max-height: 35px;
    margin-top: 22px;
    margin-left: 50px;
    }

.spanSemana{
    margin-top: 35px;
 }

 .spanAlert{
     color: red;
     font-family: "Montserrat";
     margin-bottom: 10px;
 }
.menuBloque {
    padding-top:10px;
    height:40px;
}
.menuBloque span{
    padding-left:20px;
}
.menuBloque .col-2 span{
    cursor:pointer;
}
.pointer {cursor:pointer;}
.verde {
    color: green
}
.rojo {
    color: red
}
.menuBloque {
    padding-top:10px;
    height:40px;
}
.menuBloque span{
    padding-left:20px;
}
.menuBloque .col-2 span{
    cursor:pointer;
}
.pointer {cursor:pointer;}
.verde {
    color: green
}
.rojo {
    color: red
}
.col div .imagestore{
    width: 100%;
    height: 50%;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }

.cont_card h2 {
    color: #5d5e61;
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.card {
    border-radius: 20px;
    background: #FFF;
    padding: 30px 30px 10px 30px !important;
    margin-bottom: 20px;
}


.card .imageCard img {
    width: 100%;
    height: auto;
}

.card .imageCard a{
display: flex !important;
justify-content: center !important;
align-items: center;
max-height: 88px;
overflow: hidden;
}

.card .imageCard a img{
    height: auto;
    overflow: hidden;
    margin-bottom: 10px;
}

.card .buttons {
    margin-top: 16px;
    text-align: right;
  }
  .card .buttons a {
    background: #d8d8d8;
    color: #8b8b8b;
    padding: 5px 15px;
    margin-left: 12px;
    border-radius: 20px;
    font-size: 11px;
    transition: All 0.2 ease;
    -webkit-transition: All 0.2s ease;
    -moz-transition: All 0.2 ease;
    -o-transition: All 0.2 ease;
}

.card .buttons a:hover {
    text-decoration: none;
    background: #c1c1c1;
}
.card .buttons a.publish {
    background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
    color: #FFF;
    opacity: 0.8;
}
.card .buttons a.publish:hover {
    
    opacity: 1;
}
.card .card-title {
    font-weight: 600;
    margin-top: 10px;
    font-size: 14px;
}

.card .card-title a:hover {
    color: #262931;
}

.card .other-meta {
    font-weight: 600;
    font-size: 10px;
    padding-right: 0;
}
.card .when span, .card .other-meta span {
    font-size: 10px;
    line-height: 24px;
    display: block;
    width: 100%;
}
.card .other-meta span{
    text-align: right;
}

.card .when span {
    color: #b7b7b7;
    
}



  
.flex{
    display: flex;
    flex-wrap: wrap;
}


.smooth-dnd-container {
    width: 100%;
}

.smooth-dnd-container span {
    display: block;
    width: 100%;
}

.smooth-dnd-container section {
    width: 100%;
}

.smooth-dnd-container section div {
    width: 100%;
    background: #FFF;
    overflow: hidden;
}

.smooth-dnd-container section div, .smooth-dnd-container section article div, .smooth-dnd-container section article {
    width: 100%;
    overflow: hidden;
    max-width: none;
    background-color: inherit !important;
    background: #FFF !important;
}





.cont_card h2 {
    color: #5d5e61;
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.card {
    border-radius: 20px;
    background: #FFF;
    padding: 30px 30px 10px 30px !important;
    margin-bottom: 20px;
}


.card .imageCard img {
    width: 100%;
    height: auto;
}

.card .imageCard a{
display: flex !important;
justify-content: center !important;
align-items: center;
max-height: 88px;
overflow: hidden;
}

.card .imageCard a img{
    height: auto;
    overflow: hidden;
    margin-bottom: 10px;
}

.card .buttons {
    margin-top: 16px;
    text-align: right;
  }
  .card .buttons a {
    background: #d8d8d8;
    color: #8b8b8b;
    padding: 5px 15px;
    margin-left: 12px;
    border-radius: 20px;
    font-size: 11px;
    transition: All 0.2 ease;
    -webkit-transition: All 0.2s ease;
    -moz-transition: All 0.2 ease;
    -o-transition: All 0.2 ease;
}

.card .buttons a:hover {
    text-decoration: none;
    background: #c1c1c1;
}
.card .buttons a.publish {
    background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
    color: #FFF;
    opacity: 0.8;
}
.card .buttons a.publish:hover {
    
    opacity: 1;
}
.card .card-title {
    font-weight: 600;
    margin-top: 10px;
    font-size: 14px;
}

.card .card-title a:hover {
    color: #262931;
}

.card .other-meta {
    font-weight: 600;
    font-size: 10px;
    padding-right: 0;
}
.card .when span, .card .other-meta span {
    font-size: 10px;
    line-height: 24px;
    display: block;
    width: 100%;
}
.card .other-meta span{
    text-align: right;
}

.card .when span {
    color: #b7b7b7;
    
}



  
.flex{
    display: flex;
    flex-wrap: wrap;
}


.smooth-dnd-container {
    width: 100%;
}

.smooth-dnd-container span {
    display: block;
    width: 100%;
}

.smooth-dnd-container section {
    width: 100%;
}

.smooth-dnd-container section div {
    width: 100%;
    background: #FFF;
    overflow: hidden;
}

.smooth-dnd-container section div, .smooth-dnd-container section article div, .smooth-dnd-container section article {
    width: 100%;
    overflow: hidden;
    max-width: none;
    background-color: inherit !important;
    background: #FFF !important;
}

/* .content-big-card{
    margin-bottom: 0;
} */

.Home-container{
    border: 1px solid black;
    border-radius: 25px;   
}

.content-card{
    background-color: white;
    height: 50VH;
    box-shadow: 0 0 8px 0 #7a7a7a;
    border-radius: 15px;
    margin: 6px 19px 8px -30px;
    padding: 14px 11px 26px 29px;
    display: flex;
    flex-direction: column;
}

.card-up {
    margin-top: -110px;
}

.text-metricas {
    color: #5d5e61;
    font-weight: 600;
    font-size: 2em;
    margin-bottom: 9px;
}

.card-small {
    height: 58VH;
}

h3 {
font-size: 23px;
}

.users {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titulo-metricas{
    padding-top: 32px;
    
}

.number{
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
}

.container-retos {
    display: flex;
    flex-wrap: wrap ;
}

.container-retos > div {
    padding: 12px;
    width: 40%;
    margin-right: 24px;
    height: 24vh;
}

.pictures-mas-vistos {
    border: 6px #b3ec50 solid;
    border-radius: 50%;
    width: 80%;
}

.mas-vistos {
    display: flex;
    flex-wrap: wrap;
}

.numbers-mas-vistos {
    margin: 5px 0 0 0;
    font-size: 25px;
    font-weight: bold;
    color:  #686767;
}

.contaners-hijos-retos {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jk {
    margin: 0;
}
.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}

.col-3 {
    margin-bottom: 20px;
}


.modal-registry .form-control{
    border: 1px solid #e7e8ea;
}

.modal-registry input.inputAvatar {
    box-shadow: none;
}

.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }
    
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}
.modal-registry .area_apps .area {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
}

.area {
    padding: 6px;
}

.row {
    display: flex;
    justify-content: center;
}

.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}

.col-3 {
    margin-bottom: 20px;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }

.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}
.retoitinerario {
    height: 100px;
    background-size: cover;
    background-position: 50%;
    margin: 10px 0px;
}
.retoitinerario.añade {    
    cursor: pointer;    
}
.retoitinerario div{
    line-height: 20px;
    height: 100px;
    text-align: center;
    border-radius: 5px;
    padding: 15px 0px;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.retoitinerario div span{ 

    color: white;
    display: inline-block;
    vertical-align: middle;    
}
.retoitinerario div span.galeria{    
    font-weight: bolder;
    font-size: 8em;
    padding-top:25%;
}
.retoitinerario div div.reto-title{ 
    font-size: 0.7em;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    
}
span.quitareto {
    position: absolute;
    top: 2px;
    right: 20px;
    cursor: pointer;
}
.col-3.retocont {
    overflow: hidden;
}
.retocont div div .galeria{
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    max-width: none;
    left: 0;
    height: auto !important;
    padding: 10px;
}
.adminData {
    margin: 15px;
}
.col.adminAction {
    /* margin-left: 5px;
    margin-right: 5px; */
    right: 0;
    bottom:0;  
}
.adminSave{
    position: absolute;
    right: 0;
    bottom:0; 
}
.adminAction.rojo{
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.adminAction.rojo:hover{
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.row.profile{
    margin:5px;
    padding:10px;
}
.modal-registry div div div div div img {
    margin-bottom: 10px;
    max-width: 75%;
    height: 50%;    
}
.modal-registry div div div div div .text-muted{
    margin-left: 20px;
}
.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }
.OT_publisher, .OT_subscriber {
    width: 100% !important;
    min-height: 500px !important;
}
.Participant_participant__1HfsM {
  width: 100%; }
  .Participant_participant__1HfsM video {
    display: block;
    width: 100%; }

.chat {
    flex: 1 1;
    /* margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px; */
  
  }
  
  .response-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 14px 14px 0 0;
  }
  
  .avatar-user{
    height: 52px;
    width: 52px;
    margin: 0 5px 0 7px;
    border-radius: 25px;
  }
  
  .response {
    justify-content: center;
    width: 100%; 
    padding: 10px;
    border-radius: 10px; 
    background-color: #21A4E4;
  }
  
  .username {
    color: white;
    font-family: 'AvenirNext-DemiBold';
    margin: -1px 6px 7px 0;
    font-size: 12px; 
  }
  .chatmessage {
    color: white;
    font-family: 'AvenirNext-DemiBold';
    margin: 0 6px -3px 0;
    font-size: 12px; 
  }
  
  .response-user {
    color: white;
    font-size: 14px;
    margin: 0 3px 6px 12px;
  }
  
  .write-message-box {
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 0px 0px 0px 10px;
    box-sizing: border-box;
    /* margin: 15px 0 10px 5px; */
  }
  
  .write-message, .write-message:focus  {
    height: 33px;
    width: 100%;
    background-color: white;
    font-size: 16px;
    border-radius: 7px; 
    padding-left:10px;
    outline: 0;
    border: 1px solid #b6b6b6;
  }
  
  .enter-chat {
    /* margin-left: 10px; */
    width:50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .userConnected{
    border-bottom: 1px solid gainsboro;
    padding-left:20px
  }
.newCourse-input{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
}

.newCourse-colImage{
    padding: -1px 30px 0 30px;
    max-width: 51%;
    margin-top: -87px;
}

.newCourse-colLista{
    max-width: 40%;
    width: 40%;
}
.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }
    

.newCourse-colLista{
    margin-left: 10px;
}
.newCourse-colContent{
    background-color: white;
    box-shadow: 0 0 30px 0 #999696;
    border-radius: 15px;
    max-width: 40%;
    width: 40%;
    margin-left: 80px;
    height: 30%;
}
.newCourse-Colinput{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
}

.newCourse-Select{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
}

.newCourse-check{
    margin-top: 20px;
    margin-left: 20px;
}

.newCourse-contentSwap{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 31px; 
}

.newCourse-contentSwapLi{ 
    margin-top: 10px;
    font-family: 'Montserrat';
    color: #737373;
    font-weight: 600;
    padding: 18px 0 3px 1px;
}

.newCourse-button{
    margin: 11px 0 15px 0;
    background-color: #827f81;
}

.image-logo-curso{
    width: 30%;
    margin-bottom: 20px;
}

.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}
.rolitinerario {
    height: 100px;
    background-size: cover;
    background-position: 50%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
}
.rolitinerario.añade {    
    cursor: pointer;    
}
.rolitinerario div{
    line-height: 20px;
    height: 8vh;
    width: 79%;
    text-align: center;
    border-radius: 5px;
    padding: 15px 0px;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.rolitinerario div span{ 
    color: white;
    display: inline-block;
    vertical-align: middle;    
}
.rolitinerario div span.galeria{    
    font-weight: bolder;
    font-size: 1em;
    padding: 0;
}
.rolitinerario div div.reto-title{ 
    font-size: 0.7em;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    
}
.quitarol {
    position: absolute;
    top: 2px;
    right: 20px;
    cursor: pointer;
    color: black !important;
}
.col-3.retocont {
    overflow: hidden;
}
.retocont div div .galeria{
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    max-width: none;
    left: 0;
    padding: 10px;
    background-color: #28a745;
    
}

.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}
.rolitinerario {
    height: 100px;
    background-size: cover;
    background-position: 50%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
}
.rolitinerario.añade {    
    cursor: pointer;    
}
.rolitinerario div{
    line-height: 20px;
    height: 8vh;
    width: 79%;
    text-align: center;
    border-radius: 5px;
    padding: 15px 0px;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.rolitinerario div span{ 
    color: white;
    display: inline-block;
    vertical-align: middle;    
}
.rolitinerario div span.galeria{    
    font-weight: bolder;
    font-size: 1em;
    padding: 0;
}
.rolitinerario div div.reto-title{ 
    font-size: 0.7em;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    
}
.quitarol {
    position: absolute;
    top: 2px;
    right: 20px;
    cursor: pointer;
    color: black !important;
}
.col-3.retocont {
    overflow: hidden;
}
.retocont div div .galeria{
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    max-width: none;
    left: 0;
    padding: 10px;
    background-color: #28a745;
    
}

.registroUser input.inputAvatar {
  box-shadow: none;
}
.col-12.position-relative.form-check {
  margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
  box-shadow: none;
  height: auto;
  padding-left: 15px;
  margin-left: 0px;
}
.col-12.position-relative.form-check .form-check-label {
  padding-left: 15px;
}
.activo {
  background-image: linear-gradient(-90deg, #b0e94f 0%, #459521 100%);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  padding: 10px 10px;
  color: white;
  font-weight: 700;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
  cursor: default;
}

.boton {
  font-size: 12px;
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bottonera {
  display: flex;
  flex-direction: row;
  border: 1px solid #dee1e6;
  border-radius: 100px;
  height: 45px;
  width: 30%;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.list-searchQuestions {
  padding: 10px 20px 10px 40px;
  background: url(/img/search.png) no-repeat 13px;
  background-size: 20px;
  font-style: italic;
  width: 75%;
  margin-bottom: 10px;
}

.examentest {
  height: 100px;
  background-size: cover;
  background-position: 50%;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.btn-gradient-style {
  background-image: linear-gradient(
    rgb(13, 229, 245),
    rgb(9, 148, 212),
    rgb(6, 99, 175)
  );
}

.examentest.añade {
  cursor: pointer;
}
.examentest div {
  line-height: 20px;
  height: 8vh;
  width: 79%;
  text-align: center;
  border-radius: 5px;
  padding: 15px 0px;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.examentest div span {
  color: white;
  display: inline-block;
  vertical-align: middle;
}
.examentest div span.galeria {
  font-weight: bolder;
  font-size: 1em;
  padding: 0;
}
.examentest div div.reto-title {
  font-size: 0.7em;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
}
.quitaquestion {
  position: absolute;
  top: 2px;
  right: 20px;
  cursor: pointer;
  color: black !important;
}
.col-3.retocont {
  overflow: hidden;
}
.retocont div div .galeria {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  max-width: none;
  left: 0;
  padding: 10px;
  background-color: #007bff !important;
}

.nameEncuesta {
  text-align: center
}


.inputDescriptionContainer {
  width: 200%;
  margin-top: 13px;
  line-height: 12px; }
  .inputDescriptionContainer > small {
    color: #9EA7B8;
    font-size: 12px; }

.errorContainer {
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 13px;
    font-size: 13px;
  }
  
.inputCheckbox {
    margin-bottom: 0;
    font-weight: 400;
  }
  
  .disabled {
    cursor: not-allowed;
  }
  
  .labelCheckbox {
    /* margin-left: 12px; */
    cursor: pointer;
  }
  
.inputCheckboxContainer {
  margin-bottom: 15px;
  font-size: 13px; }

.spacer {
  height: 5px; }

.inputCheckboxDescriptionContainer {
  width: 150%;
  margin-top: 2px;
  padding-left: 25px;
  line-height: 12px; }
  .inputCheckboxDescriptionContainer small {
    color: #9EA7B8;
    font-size: 12px; }

.inputTitle {
  margin-bottom: 17px;
  font-weight: 500; }

.label {
    margin-bottom: 0;
    font-weight: 500;
    color: grey;
  }
  
.addonEmail {
    width: 34px;
    height: 34px;
    margin-top: 9px;
    padding-left: 9px;
    background-color: rgba(16, 22, 34, 0.02);
    border: 1px solid #E3E9F3;
    border-right: 0;
    border-radius: 2.5px;
    color: rgba(16, 22, 34, 0.5);
    line-height: 32px;
    font-size: 13px;
    font-weight: 600!important;
    text-transform: capitalize;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
   }
   
   .addonEmail::after {
     content: '@';
     display: inline-table;
     color: #B3B5B9;
     font-size: 16px;
     font-weight: 900;
   }
   
   .inputEmailContainer {
     min-width: 200px;
     margin-bottom: 10px;
     font-size: 13px;
   }
   
   .errorAddon {
     border: 1px solid #ff203c!important;
     border-right: none!important;
   }
   
   .addonFocus {
     border-color: #78caff;
     border-right: 0;
   }
   
   .invalidEmail {
     border-color: #ff203c !important;
     border-left: 0;
   }
   
   .inputEmail {
     height: 34px;
     margin-top: 9px;
     padding-left: 10px;
     background-size: 0 !important;
     border: 1px solid #E3E9F3;
     border-left: 0px !important;
     border-radius: 2.5px;
     line-height: 34px;
     font-size: 13px;
     font-family: 'Lato' !important;
     box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
   }
   
   .inputEmail:focus {
     border-color: #78caff;
     outline: 0;
     box-shadow: none !important;
   }
   
.inputEmailWithErrors {
    min-width: 200px;
    margin-bottom: 15px;
    font-size: 13px;
    text-align: start;
  }
  
  .spacer {
    height: 5px;
  }
  
.arrowContainer {
  height: 32px;
  width: 28px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  z-index: 99; }

.arrowRight {
  position: absolute;
  top: 56px;
  right: 0; }
  .arrowRight:before {
    content: '\F054';
    vertical-align: middle;
    text-align: center;
    font-family: 'FontAwesome';
    font-size: 20px;
    font-weight: 800; }

.arrowLeft {
  position: absolute;
  top: 56px;
  left: 0; }
  .arrowLeft:before {
    content: '\F053';
    vertical-align: middle;
    text-align: center;
    font-family: 'FontAwesome';
    font-size: 20px;
    font-weight: 800; }

.imgPreviewHint {
  display: block;
  width: 100%;
  position: absolute;
  top: 49px;
  padding: 12px 75px 0 75px;
  white-space: pre-line;
  color: #333740;
  line-height: 18px;
  font-size: 13px; }
  .imgPreviewHint > span {
    display: block; }
    .imgPreviewHint > span > u {
      cursor: pointer; }

.icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: middle; }

.imgPreviewContainer {
  height: 162px;
  width: 100%;
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  text-align: center;
  vertical-align: middle;
  background-color: #333740;
  background-position: center;
  background-repeat: no-repeat !important;
  white-space: nowrap;
  z-index: 1; }
  .imgPreviewContainer > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    max-width: 100%;
    z-index: 3; }

.iconContainer {
  position: absolute;
  top: 5px;
  right: 3px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 11px;
  cursor: pointer; }

.hintDropping {
  display: block;
  position: absolute;
  top: 37px;
  z-index: 9999;
  padding: 12px 40px 0 40px;
  line-height: 18px;
  color: #fff !important;
  font-size: 13px;
  white-space: pre-line; }
  .hintDropping > span {
    display: block;
    padding: 12px 40px 0 40px;
    color: #fff; }
    .hintDropping > span > u {
      cursor: pointer; }

.fileIcon {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #fff;
  justify-content: space-around;
  font-size: 30px; }

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 162px;
  z-index: 999;
  background: #333740;
  opacity: 0.9; }

.detailBanner {
  display: flex;
  justify-content: space-between;
  line-height: 23px;
  -webkit-font-smoothing: antialiased; }
  .detailBanner > div:first-child {
    display: flex; }
    .detailBanner > div:first-child > div:nth-child(2) {
      color: #333740;
      font-size: 13px !important;
      font-weight: 400; }

.chevronDown {
  margin-right: 8px; }
  .chevronDown:before {
    content: '\F078';
    display: block;
    color: #787E8F;
    font-family: 'FontAwesome';
    font-size: 11px !important; }

.chevronRight {
  margin-right: 8px; }
  .chevronRight:before {
    content: '\F054';
    display: block;
    color: #787E8F;
    font-family: 'FontAwesome';
    font-size: 11px !important; }

.infoContainer {
  border-top: 1px solid #F6F6F6;
  padding-top: 7px; }

.infoWrapper {
  line-height: 18px;
  font-size: 13px !important; }
  .infoWrapper > span:first-child {
    color: #333740;
    font-weight: 500; }
  .infoWrapper > span:last-child {
    color: rgba(51, 55, 64, 0.8);
    font-weight: 400; }

.inputFileDetails {
  width: 100%;
  padding-top: 4px;
  margin-bottom: -13px;
  margin-top: -8px; }

.positionContainer {
  margin-left: 10px;
  color: #333740;
  font-size: 13px !important;
  -webkit-font-smoothing: antialiased; }
  .positionContainer > span:first-child {
    font-size: 13px !important;
    font-weight: 600; }
  .positionContainer > span:last-child {
    font-size: 12px;
    font-weight: 400; }

.removeContainer {
  color: #FF3000;
  font-size: 13px !important;
  font-weight: 400;
  cursor: pointer; }

.externalLink {
  color: #333740;
  text-decoration: none; }
  .externalLink:hover, .externalLink:active {
    color: #333740;
    text-decoration: none; }
  .externalLink > i, .externalLink svg {
    margin-right: 7px;
    color: #B3B5B9; }

.inputFile {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.inputFileButtonContainer {
  width: 100%;
  height: 34px;
  text-align: center;
  background-color: #FAFAFB;
  border: 1px solid #E3E9F3;
  border-top: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  color: #333740;
  font-size: 12px !important;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 35px;
  cursor: pointer;
  text-transform: uppercase; }
  .inputFileButtonContainer > i {
    margin-right: 10px; }

.copy {
  cursor: copy !important; }

.inputFileWithErrorsContainer {
  min-width: 200px;
  margin-bottom: 18px;
  margin-top: -2px;
  font-size: 13px; }

.labelFile {
  margin-bottom: 9px; }

.labelNumber {
  font-size: 13px !important;
  font-weight: 500; }

.labelContainer {
  width: 100%;
  text-align: left; }

.inputPassword {
    height: 34px;
    margin-top: 9px;
    padding-left: 10px;
    background-size: 0 !important;
    border: 1px solid #E3E9F3;
    border-radius: 2.5px;
    line-height: 34px;
    font-size: 13px;
    box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
  }
  
  .iconEyeSubWrapper {
    position: absolute;
    top: -26px;
    right: 27px;
    color: #9EA7B8;
  }
  
  .iconEyeSubWrapper:hover {
    color: black!important;
  }
  
  
  .iconEyeWrapper {
    position: relative;
  }
  
.inputPasswordWithErrors {
    min-width: 200px;
    margin-bottom: 15px;
    font-size: 13px;
    text-align: start;
  }
  
.inputText {
    height: 34px;
    margin-top: 9px;
    padding-left: 10px;
    background-size: 0 !important;
    border: 1px solid #E3E9F3;
    border-radius: 2.5px;
    line-height: 34px;
    font-size: 13px;
    font-family: 'Lato' !important;
    box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
  }
  
  /* .inputText:focus {
    border-color: #78caff;
    outline: 0;
    box-shadow: none !important;
  } */
  
.inputTextWithErrors {
    min-width: 200px;
    margin-bottom: 15px;
    font-size: 13px;
    text-align: start;
  }
  
  .spacer {
    height: 5px;
  }
  
.link, button {
    margin-bottom: 5px;
  }
  

.authPage {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 170px 0;
    background: #FAFAFB;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
  }
  
  .wrapper {
    height: 221px;
    width: 685px;
    text-align: center;
    /* background-image: url('../../images/background_empty.svg'); */
    background-position-x: center;
    font-size: 14px;
  }
  
  .errorsContainer {
    margin-top: -21px;
    margin-bottom: 18px;
    color: #ff203c;
  }
  
  .headerContainer {
    line-height: 36px;
    font-size: 24px;
    font-weight: 600;
  }
  
  .headerContainer {
    margin-bottom: 40px;
    height: 73px;
}
  .headerDescription {
    width: 416px;
    text-align: center;
    margin: auto;
    padding: 13px 30px 0 30px;
    line-height: 18px;
    color: #333740;
    font-size: 14px;
  }
  
  .formContainer {
    min-height: 200px;
    width: 416px;
    margin: 14px auto;
    margin-bottom: 0;
    padding: 33px 15px 15px 15px;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 10px 0 #b4cbf1;
  }
  
  .loginButton {
    margin-top: -6px;
    padding-right: 0;
    text-align: right;
  }
  
  .loginButton, button {
    margin-right: 16px;
  }
  
  .buttonContainer {
    margin-top: 20px;
}
  
  .linkContainer, a {
    color: #262931;
  }
  
  .logoContainer {
    position: absolute;
    left: 30px; bottom: 30px;
  }
  
  .logoContainer {
    height: 34px;
  }
  
  .separator {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    background-color: rgba(14, 22, 34, 0.04);
  }
  .accede {
   background-color: #088A68;
   background-image: linear-gradient(-90deg, #088A68, #D8F6CE);
   color: white;
   border-radius: 15px;
   border-style: none;
   height: 3.5em;
  }
  .identi {
   color: #000000;
   font-family: sans-serif;
   font-weight: bold;
  }
  .userimg {
    height: 100%;
    margin: 20px 20px;
}

  .textoacceso {
    text-align: center;
    font-size: 0.9rem;
  }
  .sombra {
    box-shadow: 0 8px 10px 0 #b4cbf1;
  }

  
  .inputText{
    font-family: inherit !important
  }
