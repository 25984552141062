.chat {
    flex: 1;
    /* margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px; */
  
  }
  
  .response-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 14px 14px 0 0;
  }
  
  .avatar-user{
    height: 52px;
    width: 52px;
    margin: 0 5px 0 7px;
    border-radius: 25px;
  }
  
  .response {
    justify-content: center;
    width: 100%; 
    padding: 10px;
    border-radius: 10px; 
    background-color: #21A4E4;
  }
  
  .username {
    color: white;
    font-family: 'AvenirNext-DemiBold';
    margin: -1px 6px 7px 0;
    font-size: 12px; 
  }
  .chatmessage {
    color: white;
    font-family: 'AvenirNext-DemiBold';
    margin: 0 6px -3px 0;
    font-size: 12px; 
  }
  
  .response-user {
    color: white;
    font-size: 14px;
    margin: 0 3px 6px 12px;
  }
  
  .write-message-box {
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 0px 0px 0px 10px;
    box-sizing: border-box;
    /* margin: 15px 0 10px 5px; */
  }
  
  .write-message, .write-message:focus  {
    height: 33px;
    width: 100%;
    background-color: white;
    font-size: 16px;
    border-radius: 7px; 
    padding-left:10px;
    outline: 0;
    border: 1px solid #b6b6b6;
  }
  
  .enter-chat {
    /* margin-left: 10px; */
    width:50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .userConnected{
    border-bottom: 1px solid gainsboro;
    padding-left:20px
  }