.cont_card h2 {
    color: #5d5e61;
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.card {
    border-radius: 20px;
    background: #FFF;
    padding: 30px 30px 10px 30px !important;
    margin-bottom: 20px;
}


.card .imageCard img {
    width: 100%;
    height: auto;
}

.card .imageCard a{
display: flex !important;
justify-content: center !important;
align-items: center;
max-height: 88px;
overflow: hidden;
}

.card .imageCard a img{
    height: auto;
    overflow: hidden;
    margin-bottom: 10px;
}

.card .buttons {
    margin-top: 16px;
    text-align: right;
  }
  .card .buttons a {
    background: #d8d8d8;
    color: #8b8b8b;
    padding: 5px 15px;
    margin-left: 12px;
    border-radius: 20px;
    font-size: 11px;
    transition: All 0.2 ease;
    -webkit-transition: All 0.2s ease;
    -moz-transition: All 0.2 ease;
    -o-transition: All 0.2 ease;
}

.card .buttons a:hover {
    text-decoration: none;
    background: #c1c1c1;
}
.card .buttons a.publish {
    background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
    color: #FFF;
    opacity: 0.8;
}
.card .buttons a.publish:hover {
    
    opacity: 1;
}
.card .card-title {
    font-weight: 600;
    margin-top: 10px;
    font-size: 14px;
}

.card .card-title a:hover {
    color: #262931;
}

.card .other-meta {
    font-weight: 600;
    font-size: 10px;
    padding-right: 0;
}
.card .when span, .card .other-meta span {
    font-size: 10px;
    line-height: 24px;
    display: block;
    width: 100%;
}
.card .other-meta span{
    text-align: right;
}

.card .when span {
    color: #b7b7b7;
    
}



  
.flex{
    display: flex;
    flex-wrap: wrap;
}


.smooth-dnd-container {
    width: 100%;
}

.smooth-dnd-container span {
    display: block;
    width: 100%;
}

.smooth-dnd-container section {
    width: 100%;
}

.smooth-dnd-container section div {
    width: 100%;
    background: #FFF;
    overflow: hidden;
}

.smooth-dnd-container section div, .smooth-dnd-container section article div, .smooth-dnd-container section article {
    width: 100%;
    overflow: hidden;
    max-width: none;
    background-color: inherit !important;
    background: #FFF !important;
}