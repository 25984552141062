.inputFileWithErrorsContainer {
    min-width: 200px;
    margin-bottom: 18px;
    margin-top: -2px;
    font-size: 13px;
  }
  
  .labelFile {
    margin-bottom: 9px;
  }
  
  .labelNumber {
    font-size: 13px !important;
    font-weight: 500;
  }
  
  .labelContainer {
    // display: flex;
    width: 100%;
    text-align: left;
  }
  