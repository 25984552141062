.nav_side .nav_ul {
    border-radius: 12px;
    width: 100%;
}

.nav_side .nav_li {
    margin-top: 12px;
    border-radius: 16px;
    padding-left: 64px;
    background: #FFF url(/img/ico/sprite_nav_vertical.png) no-repeat 9px 0;
    line-height: 34px;
    display: block;
    transition: All 0.4s ease;
    -webkit-transition: All 0.4s ease;
    -moz-transition: All 0.4s ease;
    -o-transition: All 0.4s ease;
}

.nav_side .nav_li:hover{
    background: #35a2d3 url(/img/ico/sprite_nav_vertical_hover.png) no-repeat 9px 0;
    cursor: pointer;
    
}

.nav_side .nav_li a {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 49px;
    display: block;
    width: 100%;
    color: #807e80;
}

.nav_side .nav_li a:hover{
    text-decoration: none;
}

.nav_side .nav_li:hover > a{
    color: #FFF;
}

li.nav_li.content {
    background-position-y: -245px;
}

li.nav_li.new {
    background-position-y: 0px;
}
li.nav_li.contents, li.nav_li.gestion{
    background-position-y: -45px;
}
li.nav_li.comments{
    background-position-y: -95px;
}
li.nav_li.media {
    background-position-y: -146px;
}
li.nav_li.categorias {
    background-position-y: -196px;
}
li.nav_li.notification {
    background-position-y: -630px;
    background-position-x: 8px;
}
li.nav_li.home {
    background-position-y: -245px;
    margin: 12px 0 0 0;
}
li.nav_li.users {
    background-position-y: -296px;
}

li.nav_li.retos {
    background-position-y: -403px;
    background-position-x: 8px;
}

li.nav_li.logros {
    background-position-y: -520px;
    background-position-x: 8px;
}

li.nav_li.itinerarios {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.listadocursos {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.questions {
    background-position-y: -681px;
    background-position-x: 8px;
}

li.nav_li.metricas, li.nav_li.training {
    background-position-y: -247px;
    background-position-x: 8px;
}

li.nav_li.inicio, li.nav_li.focus {
    background-position-y: -247px;
    background-position-x: 8px;
}

/* hover */

li.nav_li.content:hover {
    background-position-y: -245px;
}

li.nav_li.new:hover {
    background-position-y: 0px;
}
li.nav_li.contents:hover, li.nav_li.gestion:hover {
    background-position-y: -45px;
}
li.nav_li.comments:hover {
    background-position-y: -95px;
}
li.nav_li.media:hover {
    background-position-y: -146px;
}
li.nav_li.categorias:hover {
    background-position-y: -196px;
}
li.nav_li.notification:hover {
    background-position-y: -630px;
    background-position-x: 8px;
}
li.nav_li.home:hover {
    background-position-y: -245px;
    margin: 12px 0 0 0;
}
li.nav_li.users:hover {
    background-position-y: -296px;
}

li.nav_li.retos:hover {
    background-position-y: -403px;
    background-position-x: 8px;
}

li.nav_li.logros:hover {
    background-position-y: -520px;
    background-position-x: 8px;
}

li.nav_li.itinerarios:hover {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.listadocursos:hover {
    background-position-y: -461px;
    background-position-x: 8px;
}

li.nav_li.questions:hover {
    background-position-y: -681px;
    background-position-x: 8px;
}
li.nav_li.exam, li.nav_li.exam:hover {
    background-position-y: -733px;
    background-position-x: 8px;
}
li.nav_li.convocatoria, li.nav_li.convocatoria:hover {
    background-position-y: -783px;
    background-position-x: 8px;
}

li.nav_li.metricas:hover, li.nav_li.training:hover {
    background-position-y: -247px;
    background-position-x: 8px;
}

li.nav_li.inicio, li.nav_li.focus:hover {
    background-position-y: -247px;
    background-position-x: 8px;
}