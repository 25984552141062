.adminData {
    margin: 15px;
}
.col.adminAction {
    /* margin-left: 5px;
    margin-right: 5px; */
    right: 0;
    bottom:0;  
}
.adminSave{
    position: absolute;
    right: 0;
    bottom:0; 
}
.adminAction.rojo{
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.adminAction.rojo:hover{
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.row.profile{
    margin:5px;
    padding:10px;
}
.modal-registry div div div div div img {
    margin-bottom: 10px;
    max-width: 75%;
    height: 50%;    
}
.modal-registry div div div div div .text-muted{
    margin-left: 20px;
}