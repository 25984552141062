.rounded_contAn{
    border-radius: 16px;
    background: #FFF;
    margin-bottom: 20px;
  }

.rounded_contAn.contents{
    margin-bottom: 10px;
    padding: 15px;
    font-size: 12px;
}  

.statCol{
    height: 200px;
    max-width: 250px;
    margin-left: 20px;
}

.statText{
    font-size: 1rem;
    font-weight:bolder;
}