h3{
 font-size: 50px;
}
.listacursos-col{
    margin-top: 20px
}

.btn-gradient-style{
background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
}

.btn-gradient-style2{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    max-width: 150px;
    max-height: 35px;
    margin-top: 22px;
    margin-left: 50px;
    }

.spanSemana{
    margin-top: 35px;
 }

 .spanAlert{
     color: red;
     font-family: "Montserrat";
     margin-bottom: 10px;
 }