.menuBloque {
    padding-top:10px;
    height:40px;
}
.menuBloque span{
    padding-left:20px;
}
.menuBloque .col-2 span{
    cursor:pointer;
}
.pointer {cursor:pointer;}
.verde {
    color: green
}
.rojo {
    color: red
}
.col div .imagestore{
    width: 100%;
    height: 50%;
}