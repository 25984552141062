.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}

.col-3 {
    margin-bottom: 20px;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }